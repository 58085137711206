import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment-with-locales-es6'

import { getLocale } from '../../../store/commonSlice'
import { getVisibleForecasts } from '../../../store/stationForecastPageSlice'
import { IconSound } from '../../../resources/icons'
import { PrimaryButton } from '../../../components/Button'

import './Speaker.scss'

/* eslint react/prop-types: ["off"] */
class Speaker extends Component {
    constructor(props) {
        super(props)

        this.mounted = false
        this.state = {
            speaking: false,
        }
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.stopSpeaking()

        this.mounted = false
    }

    startSpeaking = () => {
        const { intl, locale, forecasts } = this.props
        const { speaking } = this.state

        if (!window.SpeechSynthesisUtterance) {
            // eslint-disable-next-line
            alert(intl.formatMessage({ id: 'pages.stationForecasts.noSpeechAlert' }))
        }

        if (speaking || !window.SpeechSynthesisUtterance) {
            return
        }

        moment.locale(locale)
        const message = new SpeechSynthesisUtterance()
        message.volume = 1
        message.rate = 1
        message.pitch = 0.6
        message.text = ''
        message.lang = locale
        message.voice = speechSynthesis.getVoices().find(item => item.lang.indexOf(locale) > -1)

        if (forecasts.length) {
            forecasts.forEach((route) => {
                if (route.fores && route.fores.length) {
                    let timeStr

                    if (route.fores[0].state_id === 3) {
                        const { arrt } = route.fores[0]

                        const now = moment()
                        const timeInMinutes = Math.round(arrt / 60)
                        const timeInSeconds = Math.min(arrt - timeInMinutes * 60, 10)

                        if (timeInMinutes === 0) {
                            const arrivial = now.add(timeInSeconds, 'seconds')
                            timeStr = arrivial.fromNow()
                        } else {
                            const arrivial = now.add(timeInMinutes, 'minutes')
                            timeStr = arrivial.fromNow()
                        }
                        message.text += intl.formatMessage(
                            { id: 'pages.stationForecasts.speaker.oneForecastTemplate' },
                            { routeNumber: route.rnum, time: timeStr },
                        )
                    }

                    if (route.fores[0].state_id === 4) {
                        message.text += intl.formatMessage(
                            { id: 'pages.stationForecasts.speaker.onFinalTemplate' },
                            { routeNumber: route.rnum },
                        )
                    }
                }
                if (route.info === 'no_transports_in_our_direction') {
                    message.text += intl.formatMessage(
                        { id: 'pages.stationForecasts.speaker.otherTemplate' },
                        { routeNumber: route.rnum },
                    )
                }
                if (route.info === 'no_transports_with_gps') {
                    message.text += intl.formatMessage(
                        { id: 'pages.stationForecasts.speaker.noDataTemplate' },
                        { routeNumber: route.rnum },
                    )
                }
            })
        } else {
            message.text = intl.formatMessage({ id: 'pages.stationForecasts.speaker.noForecastsTemplate' })
        }

        speechSynthesis.speak(message)
        this.setState({ speaking: true })

        message.onend = this.stopSpeaking
    }

    stopSpeaking = () => {
        const { speaking } = this.state

        if (!speaking) {
            return
        }

        speechSynthesis.cancel()

        if (this.mounted) {
            this.setState({ speaking: false })
        }
    }

    render() {
        const { speaking } = this.state

        return (
            <div className="speaker-wrapper">
                <PrimaryButton
                    style={{
                        display: 'inline-block',
                        margin: '12px auto',
                        padding: '0 40px',
                    }}
                    onClick={speaking ? this.stopSpeaking : this.startSpeaking}
                >
                    <IconSound />
                    <FormattedMessage
                        id={
                            speaking
                                ? 'pages.stationForecasts.speaker.buttonOn'
                                : 'pages.stationForecasts.speaker.buttonOff'
                        }
                    />
                </PrimaryButton>
            </div>
        )
    }
}

export default injectIntl(
    connect(state => ({
        locale: getLocale(state),
        forecasts: getVisibleForecasts(state),
    }))(Speaker),
)
