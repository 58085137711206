import React, { Suspense } from 'react'

import LoadingScreen from '../../components/StationSearch/LoadingScreen'

const Map = React.lazy(() => import('./Map'))

const MapPage = () => (
    <Suspense fallback={<LoadingScreen />}>
        <Map />
    </Suspense>
)

export default MapPage
