import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'

export const MAP_PAGE_REDUCER_KEY = 'mapPage'

const mapPageSlice = createSlice({
    name: MAP_PAGE_REDUCER_KEY,
    initialState: {
        positions: [],
        filters: {},
    },
    reducers: {
        setPositions: (state, { payload: positions }) => {
            state.positions = positions
        },
        unloadTransportPositions: state => {
            state.positions = []
        },
        setFilters: (state, { payload }) => {
            state.filters = payload
        },
    },
})

export const { unloadTransportPositions, setFilters } = mapPageSlice.actions

export const getMapPage = state => state.pages[MAP_PAGE_REDUCER_KEY]
export const getPositions = state => getMapPage(state).positions
export const getFilters = state => getMapPage(state).filters

export const fetchTransportPositions =
    ({ filters }) =>
        async (dispatch) => {
            try {
                const params = { rids: null, curk: 0 }

                if (filters?.routesFilter && Array.isArray(filters?.routesFilter)) {
                    params.rids = filters.routesFilter.join(',')
                }

                const { data } = await axios.get('/getVehicleAnimationsNoProtect.php', {
                    params,
                })

                const positions = data.map(item => ({
                    id: +item.id,
                    lat: +item.lat / 1000000,
                    lng: +item.lng / 1000000,
                    dir: +item.dir,
                    rtype: item.rtype,
                    rnum: item.rnum,
                }))

                dispatch(mapPageSlice.actions.setPositions(positions))
            } catch (e) {
            // eslint-disable-next-line no-console
                console.error(e)
            // FIXME
            // dispatch(stationForecastPageSlice.actions.forecastsFetchFail(e.toString()))
            }
        }

export default mapPageSlice.reducer
