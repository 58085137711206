import axios from 'axios'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import { push as navigateTo } from 'connected-react-router'
import mapValues from 'lodash-es/mapValues'
import memoize from 'lodash.memoize'

import config from '../resources/config'
import { testForecastData, testStationInfo } from '../resources/test/stationForecastTestData'
import { localStorageGetItem, localStorageSetItem } from '../services/localStorage'
import { allContains } from '../services/utils'

const EXCEPTED_ROUTES_LOCAL_STORAGE_KEY = 'EXCEPTED_ROUTES'

export const STATION_FORECAST_PAGE_REDUCER_KEY = 'stationForecastPage'

const stationForecastPageSlice = createSlice({
    name: STATION_FORECAST_PAGE_REDUCER_KEY,
    initialState: {
        stationId: null,
        stationInfo: null,
        exceptTransportType: config.pages[STATION_FORECAST_PAGE_REDUCER_KEY].exceptTransportType,
        goalZoneIds: config.pages[STATION_FORECAST_PAGE_REDUCER_KEY].goalZoneIds,
        transportColumnDescView: config.pages[STATION_FORECAST_PAGE_REDUCER_KEY].transportColumnDescView,
        forecastsPending: false,
        forecasts: null,
        isTest: false,
        exceptedRoutes: {},
    },
    reducers: {
        pageOpened: (state, { payload: { stationId, isTest } }) => {
            state.stationId = stationId
            state.stationInfo = null
            state.isTest = isTest

            return state
        },
        pageClosed: (state) => {
            state.stationId = null
            state.stationInfo = null
            state.isTest = false

            return state
        },
        setStationInfo: (state, { payload }) => {
            state.stationInfo = payload

            return state
        },
        setExceptTransportType: (state, { payload }) => {
            state.exceptTransportType = payload

            return state
        },
        setGoalZoneIds: (state, { payload }) => {
            state.goalZoneIds = payload

            return state
        },
        setTransportColumnDescView: (state, { payload }) => {
            state.transportColumnDescView = payload

            return state
        },
        setForecastsPending: (state) => {
            state.forecastsPending = true

            return state
        },
        forecastsFetchFail: (state) => {
            state.forecastsPending = false
            state.forecasts = null

            return state
        },
        setForecasts: (state, { payload }) => {
            state.forecastsPending = false
            state.forecasts = payload

            return state
        },
        setExceptedRoutes: (state, { payload }) => {
            state.exceptedRoutes = payload
        },
        addToExceptedRoutes: (state, { payload: { stationId, routeIds } }) => {
            let exceptedRoutesForStation
            if (typeof state.exceptedRoutes[stationId] === 'undefined') {
                exceptedRoutesForStation = new Set()
            } else {
                exceptedRoutesForStation = new Set(state.exceptedRoutes[stationId])
            }
            routeIds.forEach(id => exceptedRoutesForStation.add(id))
            state.exceptedRoutes[stationId] = Array.from(exceptedRoutesForStation)
        },
        removeFromExceptedRoutes: (state, { payload: { stationId, routeIds } }) => {
            let exceptedRoutesForStation
            if (typeof state.exceptedRoutes[stationId] === 'undefined') {
                exceptedRoutesForStation = new Set()
            } else {
                exceptedRoutesForStation = new Set(state.exceptedRoutes[stationId])
            }
            routeIds.forEach(id => exceptedRoutesForStation.delete(id))
            state.exceptedRoutes[stationId] = Array.from(exceptedRoutesForStation)
        },
    },
})

export const fetchStationInfo = () => async (dispatch, getState) => {
    let stationId
    const state = getState()

    if (getIsTest(state)) {
        dispatch(setStationInfo(testStationInfo))
    } else {
        try {
            stationId = getStationId(state)

            const { data } = await axios.get('/getStationInfo.php', { params: { sid: stationId } })

            data.zones = mapValues(data.zones, (zone) => {
                zone.zone_id = zone.zone_id.toString()
                zone.zonecategory_id = zone.zonecategory_id.toString()
                zone.route_ids = zone.route_ids.map(routeId => routeId.toString())

                return zone
            })

            const currentState = getState()
            const currentStationId = getStationId(currentState)

            if (currentStationId === stationId) {
                if (data && data.name && data.name.length) {
                    dispatch(setStationInfo(data))
                } else if (stationId !== '76') {
                    // Нужна проверка для предотвращения зацикливания, т.к. 76 это дефолтный id при открытии
                    // eslint-disable-next-line no-console
                    console.error(`Остановка с id="${stationId}" не найдена`)
                    dispatch(navigateTo('/'))
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Ошибка получения остановки с id="${stationId}": `, e)
            dispatch(navigateTo('/'))
        }
    }
}

export const fetchForecasts = () => async (dispatch, getState) => {
    const state = getState()

    // если страница для теста, выводим данные по прогнозу из файла
    if (getIsTest(state)) {
        dispatch(stationForecastPageSlice.actions.setForecasts(testForecastData))
    } else {
        try {
            const stationId = getStationId(state)
            const pending = getForecastsPending(state)

            if (stationId === null || pending) {
                return
            }

            dispatch(stationForecastPageSlice.actions.setForecastsPending())

            const { data } = await axios.get('/getStationForecastsMaxInfo.php', {
                params: { sid: stationId },
            })

            data.forEach((route) => {
                if (route.nearby_time === null) {
                    if (route.fores.length) {
                        route.nearby_time = route.fores[route.fores.length - 1].arrt
                    } else {
                        route.nearby_time = Infinity
                    }
                }
            })

            data.sort((a, b) => a.nearby_time - b.nearby_time)

            const stuctedData = data.map(route => ({
                rid: route.rid.toString(),
                rnum: route.rnum,
                rtype: route.rtype,
                info: route.info,
                fores: route.fores.slice(0, 3).map((forecast) => {
                    const { rid, rnum, rtype, ...needed } = forecast
                    return {
                        ...needed,
                        rid: forecast.rid.toString(),
                        obj_tags: forecast.obj_tags.map(objTag => objTag.toString()),
                    }
                }),
            }))

            dispatch(stationForecastPageSlice.actions.setForecasts(stuctedData))
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
            dispatch(stationForecastPageSlice.actions.forecastsFetchFail(e.toString()))
        }
    }
}

export const {
    pageOpened,
    pageClosed,
    setStationInfo,
    setExceptTransportType,
    setGoalZoneIds,
    setTransportColumnDescView,
    forecastsFetchFail,
    setForecasts,
    setExceptedRoutes,
} = stationForecastPageSlice.actions

export const getStationForecastPage = state => state.pages[STATION_FORECAST_PAGE_REDUCER_KEY]
export const getStationId = state => getStationForecastPage(state).stationId
export const getIsTest = state => getStationForecastPage(state).isTest
export const getStationInfo = state => getStationForecastPage(state).stationInfo
export const getExceptTransportType = state => getStationForecastPage(state).exceptTransportType
export const getGoalZoneIds = state => getStationForecastPage(state).goalZoneIds
export const getTransportColumnDescView = state => getStationForecastPage(state).transportColumnDescView
export const getForecastsPending = state => getStationForecastPage(state).forecastsPending
export const getForecasts = state => getStationForecastPage(state).forecasts
export const getExceptedRoutes = state => getStationForecastPage(state).exceptedRoutes
export const getVisibleForecasts = createSelector(
    [getStationInfo, getExceptTransportType, getGoalZoneIds, getForecasts, getStationId, getExceptedRoutes],
    (stationInfo, exceptTransportType, goalZoneIds, forecasts, stationId, exceptedRoutes) => {
        if (!stationInfo || !forecasts) {
            return []
        }

        // Visible route types

        const visibleRouteTypes = stationInfo.routeTypes.map(routeType => routeType.short_name)
        // общий фильтр по типам транспорта убран
        // .filter(routeType => !exceptTransportType.includes(routeType))

        // Visible route ids

        let visibleRouteIds = []

        if (goalZoneIds && goalZoneIds.length) {
            visibleRouteIds = goalZoneIds.reduce((visibleRouteIds, goalZoneId) => {
                if (stationInfo.zones[goalZoneId]) {
                    const routeIds = stationInfo.zones[goalZoneId].route_ids

                    routeIds.forEach((routeId) => {
                        if (!visibleRouteIds.includes(routeId)) {
                            visibleRouteIds.push(routeId)
                        }
                    })
                }

                return visibleRouteIds
            }, [])
        }

        // excepted routes
        const stationExceptedRoutes = getExceptedRoutesArray(exceptedRoutes, stationId)

        return forecasts
            .filter(({ rtype }) => visibleRouteTypes.includes(rtype))
            .filter(({ rid }) => !visibleRouteIds.length || visibleRouteIds.includes(rid))
            .filter(({ rid }) => !stationExceptedRoutes.includes(+rid))
    },
)
export const getStationInfoRoutes = createSelector([getStationInfo], stationInfo => memoize((transportType) => {
    if (Array.isArray(stationInfo.routes)) {
        return stationInfo.routes.filter(route => route.route_type === transportType)
    }
    return []
}))

const getExceptedRoutesArray = (exceptedRoutes, stationId) => {
    if (Array.isArray(exceptedRoutes[stationId])) {
        return exceptedRoutes[stationId]
    }
    return []
}


export const getExceptedRoutesForStation = createSelector([getExceptedRoutes],
    exceptedRoutes => memoize(stationId => getExceptedRoutesArray(exceptedRoutes, stationId)))

export const isExceptedTransportTypeForCurrentStation = createSelector(
    [getStationId, getExceptedRoutes, getStationInfoRoutes],
    (stationId, exceptedRoutes, getStationInfoRoutes) => memoize((transportType) => {
        const stationRoutes = getStationInfoRoutes(transportType)
            .map(item => item.route_ids)
            .reduce((acc, cur) => acc.concat(cur), [])

        const stationExceptedRoutes = getExceptedRoutesArray(exceptedRoutes, stationId)

        return allContains(stationRoutes, stationExceptedRoutes)
    }),
)

export const initExceptedRoutes = () => (dispatch) => {
    const exceptedRoutes = localStorageGetItem(EXCEPTED_ROUTES_LOCAL_STORAGE_KEY)
    if (exceptedRoutes && typeof exceptedRoutes === 'object') {
        dispatch(stationForecastPageSlice.actions.setExceptedRoutes(exceptedRoutes))
    }
}

const saveExceptedRoutes = () => (dispatch, getState) => {
    const exceptedRoutes = getExceptedRoutes(getState())
    localStorageSetItem(EXCEPTED_ROUTES_LOCAL_STORAGE_KEY, exceptedRoutes)
}

export const addToExceptedRoutes = (stationId, routeIds) => async (dispatch, getState) => {
    await dispatch(stationForecastPageSlice.actions.addToExceptedRoutes({ stationId, routeIds }))
    dispatch(saveExceptedRoutes())
}

export const removeFromExceptedRoutes = (stationId, routeIds) => async (dispatch, getState) => {
    await dispatch(stationForecastPageSlice.actions.removeFromExceptedRoutes({ stationId, routeIds }))
    dispatch(saveExceptedRoutes())
}

export const toggleExceptedRoutes = (transportType, position) => async (dispatch, getState) => {
    const stationId = getStationId(getState())
    const stationRoutes = getStationInfoRoutes(getState())(transportType)
        .map(item => item.route_ids)
        .reduce((acc, cur) => acc.concat(cur), [])

    if (position) {
        await dispatch(
            stationForecastPageSlice.actions.removeFromExceptedRoutes({ stationId, routeIds: stationRoutes }),
        )
    } else {
        await dispatch(stationForecastPageSlice.actions.addToExceptedRoutes({ stationId, routeIds: stationRoutes }))
    }
    dispatch(saveExceptedRoutes())
}

export default stationForecastPageSlice.reducer
